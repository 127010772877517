var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TopBar',{attrs:{"title":_vm.title}},[_c('template',{slot:"actions"},[_c('v-btn',{staticClass:"mb-0 mr-4 px-4",attrs:{"outlined":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" "+_vm._s(_vm.$t("btn.cancel"))+" ")]),(!_vm.readonly())?_c('v-btn',{staticClass:"mb-0 px-4",attrs:{"form":"userGroupForm","color":"primary","type":"submit","loading":_vm.isUserGroupSubmitted}},[_vm._v(" "+_vm._s(_vm.$t("btn.save"))+" ")]):_vm._e()],1)],2),_c('v-container',{staticClass:"h-100",attrs:{"id":"user_group-form","fluid":"","tag":"section"}},[_c('validation-observer',{ref:"validationObserver"},[_c('v-form',{staticClass:"pt-8",attrs:{"id":"userGroupForm"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"id":"training_location","color":"primary","inline":"","dark":_vm.$vuetify.theme.dark,"icon":"mdi-map-marker"}},[_c('template',{slot:"bottom"},[_c('span',{staticClass:"text-subtitle-1 pl-2"},[_vm._v(_vm._s(_vm.$t("form.required")))])]),_c('template',{slot:"title"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("user_group.title_header"))+" ")])],1),_c('v-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('user_group.form.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('user_group.form.name') + '*',"outlined":"","name":"name","readonly":_vm.readonly()},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])}),(!_vm.readonly())?_c('v-btn',{staticClass:"my-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.associateUserGroup()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.add_grant'))+" ")]):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 my-3",attrs:{"headers":_vm.roles_headers,"items":_vm.mapUserRoles,"items-per-page":5},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('roles.' + item.role))+" ")]}},(!_vm.readonly())?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center ma-2 rounded"},[_c('v-avatar',{staticClass:"rounded-lg",attrs:{"tile":"","color":"primary"}},[_c('v-icon',{on:{"click":function($event){return _vm.dissociateRole(item)}}},[_vm._v(" mdi-delete ")])],1)],1)]}}:null],null,true)}),(!_vm.readonly())?_c('v-btn',{staticClass:"my-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.associateUser()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.add_user'))+" ")]):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 my-3",attrs:{"headers":_vm.users_headers,"items":_vm.userGroup.users,"items-per-page":5},scopedSlots:_vm._u([(!_vm.readonly())?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center ma-2 rounded"},[_c('v-avatar',{staticClass:"rounded-lg",attrs:{"tile":"","color":"primary"}},[_c('v-icon',{on:{"click":function($event){return _vm.dissociateUser(item.id)}}},[_vm._v(" mdi-delete ")])],1)],1)]}}:null],null,true)})],1)],2)],1)],1)],1),_c('cancel-update-dialog',{attrs:{"saving":_vm.isUserGroupSubmitted},on:{"cancel":function($event){return _vm.onCancel()},"save":function($event){return _vm.onSave()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }