


















































































































































import { defineComponent } from '@vue/composition-api'
import TopBar from '@/components/organisms/o-top-bar.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { RawLocation } from 'vue-router'
import { mapFields } from 'vuex-map-fields'
import cancelSaveDialog from '@/mixins/cancelSaveDialog'
import { UserGroup } from '@/api/interfaces/userGroup'
import store from '@/store'
import { User } from '@/api/interfaces/user'
import { isReadonly } from '@/composables/UserGrant'

export default defineComponent({
  setup () {
    const readonly = () => isReadonly()
    return {
      readonly,
    }
  },
  name: 'UserGroupForm',
  components: {
    TopBar,
    CancelUpdateDialog: () =>
      import('../../../components/cancel-update-dialog.vue'),
  },
  data () {
    return {
      title: this.$t('user_group.title_header'),
      roles_headers: [
        {
          text: this.$t('roles.headers.role'),
          align: 'start',
          value: 'role',
        },
        {
          text: this.$t('roles.headers.actions'),
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      users_headers: [
        {
          text: this.$t('user.list.headers.email'),
          align: 'start',
          value: 'email',
        },
        {
          text: this.$t('user.list.headers.firstName'),
          value: 'firstName',
        },
        {
          text: this.$t('user.list.headers.lastName'),
          align: 'start',
          value: 'lastName',
        },
        {
          text: this.$t('user.list.headers.actions'),
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
    }
  },
  mixins: [cancelSaveDialog],
  computed: {
    ...mapState('userGroupForm', {
      isUserGroupSubmitted: 'isSubmitted',
      isFormChanged: 'isChanged',
      userGroup: 'userGroup',
    }),
    ...mapGetters('navigation', {
      getLastSavedRoute: 'getLastSavedRoute',
    }),
    ...mapFields('userGroupForm', [
      'userGroup.name',
      'userGroup.roles',
    ]),
    idUserGroup () {
      return this.$route.params.idUserGroup || null
    },
    mapUserRoles () {
      const roles = (this.userGroup as UserGroup).roles
      if (roles) {
        return roles.map((roleUser: string) => {
          return {
            role: roleUser,
          }
        })
      }
      return []
    },
  },
  async created () {
    await this.init()
  },
  methods: {
    async init () {
      if (this.idUserGroup && !(this.userGroup as UserGroup)['@id']) {
        await this.loadById(
          this.idUserGroup,
        )
      }
    },
    ...mapActions('userGroupForm', {
      loadById: 'loadById',
      save: 'save',
      reset: 'reset',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
    }),
    ...mapMutations('navigation', {
      forceCancelSaveNextRoute: 'forceCancelSaveNextRoute',
    }),
    async onSubmit () {
      const isValidatedForm = await (this as any).isValidatedForm(
        this.$refs.validationObserver,
      )
      if (isValidatedForm) {
        await this.save(this.userGroup)
        this.sendSuccessMessage('user_group.form.saved')
        this.returnPrevious()
      }
    },
    dissociateRole (item: { role: string }) {
      const index: number | undefined = (this.userGroup as UserGroup)?.roles?.indexOf(item.role)
      const roles = (this.userGroup as UserGroup).roles
      if (roles && index !== -1 && index !== undefined) {
        roles.splice(index, 1)
      }
    },
    dissociateUser (userId: number) {
      const index = ((this.userGroup as UserGroup)?.users as Array<User>)?.findIndex(user => user.id === userId)
      const users = (this.userGroup as UserGroup).users
      if (users) {
        users.splice(index, 1)
      }
    },
    cancel () {
      this.returnPrevious()
    },
    returnPrevious () {
      if (this.getReturnPreviousRoute) {
        this.$router.push(this.getReturnPreviousRoute as RawLocation)
      } else {
        this.$router.push({ name: 'UserGroup List' })
      }
    },
    associateUserGroup () {
      const route: RawLocation = {
        name: 'UserGroup List Associate Grant',
        params: { idUserGroup: this.idUserGroup as string },
      }
      this.forceCancelSaveNextRoute(route)
      this.$router.push(route)
    },
    associateUser () {
      const route: RawLocation = {
        name: 'UserGroup List Associate User',
        params: { idUserGroup: this.idUserGroup as string },
      }
      this.forceCancelSaveNextRoute(route)
      this.$router.push(route)
    },
  },
  beforeRouteUpdate (to, _from, next) {
    store.commit('navigation/removeRoute')
    store.commit('navigation/addRoute', to)
    next()
  },
  beforeRouteEnter (to, _from, next) {
    store.commit('navigation/addRoute', to)
    next()
  },
  beforeRouteLeave (to, _from, next) {
    if (to.name !== 'UserGroup List Associate Grant' && to.name !== 'UserGroup List Associate User') {
      this.reset()
      store.commit('navigation/removeRoute')
    }
    next()
  },
})
